import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
  Input, Label, Dropdown, Spinner, Button, Icon, TextArea
} from 'rtm-ui-components';
import moment from 'moment';
import { isValidIp } from '../../../utils';
import TableHeader from '../../SharedComponents/Table/TableHeader/TableHeader';
import TableCell from '../../SharedComponents/Table/TableCell/TableCell';
import './LogsHeader.css';
import * as notificationsActions from '../../../store/actions/notifications';

class LogsHeader extends Component {
  state = {
    fromDate: moment().subtract(2, 'days').format('YYYY-MM-DD'),
    fromTime: moment().subtract(2, 'days').format('HH:mm'),
    toDate: moment().format('YYYY-MM-DD'),
    toTime: moment().format('HH:mm'),
    bannerText : "",
    activeFilters: {},
    ipError: undefined,
  }

  filterName = async (value) => {
    const { addFilter, removeFilter } = this.props;
    if (value) {
      await addFilter(value, 'user');
    } else {
      await removeFilter('user');
    }
  }

  filterAffectedTarget = async (value) => {
    const { addFilter, removeFilter } = this.props;
    if (value) {
      await addFilter(value, 'affectedTarget');
    } else {
      await removeFilter('affectedTarget');
    }
  }

  filterSkippedUser = async (value) => {
    const { addFilter, removeFilter } = this.props;
    if (value) {
      await addFilter(value, 'skippedUser');
    } else {
      await removeFilter('skippedUser');
    }
  }

  filterIP = async (value) => {
    const { addFilter, removeFilter } = this.props;
    if (value) {
      if (isValidIp(value)) {
        await addFilter(value, 'ipAddress');
        this.setState({ ipError: undefined });
      } else this.setState({ ipError: 'Invalid IP address' });
    } else {
      await removeFilter('ipAddress');
      this.setState({ ipError: undefined });
    }
  }

  onOperationChange = (value) => {
    const { addFilter, removeFilter, setNotifications } = this.props;
    const { activeFilters } = this.state;
    if (value === 'All') {
      removeFilter('type');
    } else {
      addFilter(value, 'type');
    }

    this.setState({ activeFilters: { ...activeFilters, type: value } });
  }

  isTimeButtonActive = () => {
    const { fromDate, toDate, activeFilters } = this.state;
    if (fromDate || toDate || activeFilters.from || activeFilters.to) {
      return false;
    }
    return true;
  }

  setNotifications = () => {
    const{
      setNotification,
    } = this.props;
    const{
      bannerText
    } = this.state;
    setNotification(bannerText);
    window.location.href = window.location.href;
  }

  deleteNotifications = () => {
    const{
      deleteNotification,
    } = this.props;
    deleteNotification();
  }

  saveTimeRange = () => {
    const {
      removeFilter, addFilter, addAndRemoveFilter,
    } = this.props;
    const {
      fromDate, fromTime, toDate, toTime, activeFilters,
    } = this.state;

    if (fromDate && toDate) {
      const from = new Date(fromDate);
      const to = new Date(toDate);

      from.setHours(fromTime.substring(0, 2));
      from.setMinutes(fromTime.substring(3, 5));

      to.setHours(toTime.substring(0, 2));
      to.setMinutes(toTime.substring(3, 5));

      addFilter(from.toISOString(), 'from', to.toISOString(), 'to');
      this.setState({ activeFilters: { ...activeFilters, to, from } });
    } else if (fromDate) {
      const from = new Date(fromDate);
      from.setHours(fromTime.substring(0, 2));
      from.setMinutes(fromTime.substring(3, 5));

      addAndRemoveFilter('from', from.toISOString(), 'to');
      this.setState({ activeFilters: { ...activeFilters, to: '', from } });
    } else if (toDate) {
      const to = new Date(toDate);
      to.setHours(toTime.substring(0, 2));
      to.setMinutes(toTime.substring(3, 5));
      addAndRemoveFilter('to', to.toISOString(), 'from');
      this.setState({ activeFilters: { ...activeFilters, from: '', to } });
    } else {
      removeFilter('from', 'to');
      this.setState({ activeFilters: { ...activeFilters, to: '', from: '' } });
    }
  }

  render() {
    const { operations, orderContent, value } = this.props;
    const {
      fromDate, fromTime, toDate, toTime, ipError, bannerText,
    } = this.state;
    const timeContent = (
      <div className="logs-time">
        <div className="time-interval-wrapper">
          <div className="text-from">
            <Label text="Time range starting from" />
            <div className="time-inputs">
              <Input
                name="from-date"
                id="filter-from-date"
                value={fromDate}
                type="date"
                onChange={({ target }) => this.setState({ fromDate: target.value })}
              />
              <Input
                name="from-date"
                id="filter-from-time"
                value={fromTime}
                type="time"
                onChange={({ target }) => {
                  if (target.value === '') {
                    this.setState({ fromTime: '00:00' });
                  } else {
                    this.setState({ fromTime: target.value });
                  }
                }}
              />
            </div>
          </div>
          <div className="time-range-divider"><Label text="-" /></div>
          <div className="text-to">
            <Label text="Time range ends to" />
            <div className="time-inputs">
              <Input
                className="filter-to-date"
                name="from-date"
                id="filter-to-date"
                value={toDate}
                type="date"
                onChange={({ target }) => this.setState({ toDate: target.value })}
              />
              <Input
                name="from-date"
                id="filter-to-time"
                className="filter-to-time"
                value={toTime}
                type="time"
                onChange={({ target }) => {
                  if (target.value === '') {
                    this.setState({ toTime: '00:00' });
                  } else {
                    this.setState({ toTime: target.value });
                  }
                }}
              />
            </div>
          </div>
        </div>
        <div className="logs-button">
          <Button
            className="filter-save"
            variant="primary"
            id="logs-time-filter"
            disabled={this.isTimeButtonActive()}
            onClick={() => this.saveTimeRange()}
          >Save
          </Button>
        </div>
      </div>
    );
    const options = [
      {
        id: 'All', key: 'All', value: 'All', text: 'All',
      },
      ...Object.keys(operations).map(op => ({
        id: op,
        key: operations[op],
        value: op,
        text: operations[op],
      })),
    ];
    return (
      <div className="logs-header-container">
        <div className="create-banner-container">
          <div className="banner-label">
          <Label text="Customize the banner" />
          <div className='tooltip'>
            <Icon name="info"></Icon>
            <span className='tooltiptext'>Create, customize or remove banner from UI. Used mainly to inform about maintenance times</span>
          </div>
          <TextArea
          id="banner-text"
          value={bannerText}
          type="text"
          onChange={({ target }) => this.setState({ bannerText: target.value })}
          />
          </div>
          <div className="banner-buttons">
            <Button 
            variant="primary" 
            className="banner-add-save-button"
            onClick={() => this.setNotifications()}>Apply</Button>
            <Button 
            variant="secondary" 
            className="banner-disable-button"
            onClick={() => this.deleteNotifications()}>Disable banner</Button>
          </div>
        </div>
        <div className="audit-logs-container">
        <h3>Audit logs</h3>
        <div className="time-actions-wrapper">
          {timeContent}
        </div>
        <div className="filter-user-ip-wrapper">
        { !options ? <Spinner /> : (
            <div className="action-type-wrapper">
              <Label text="Action type" />
              <Dropdown
                fluid
                id="action-type-filter"
                className="action-type-dropdown"
                defaultValue="All"
                options={options || null}
                onChange={item => this.onOperationChange(item.value)}
              />
            </div>
          )}
          <div className="filter-user-input-wrapper">
            <Label htmlFor="filter-user" text="User" />
            <Input
              name="filter-user"
              id="filter-user"
              placeholder="Name or ID..."
              debounceTimer={300}
              onChange={event => this.filterName(event.target.value)}
            />
          </div>
          <div className="filter-skipped-user-input-wrapper">
            <Label htmlFor="filter-skipped-user" text="Filter out a user" />
            <Input
              name="filter-skipped-user"
              id="filter-skipped-user"
              placeholder="Username"
              debounceTimer={300}
              onChange={event => this.filterSkippedUser(event.target.value)}
            />
          </div>
          <div className="filter-affected-target-input-wrapper">
            <Label htmlFor="filter-affected-target" text="Affected Target" />
            <Input
              name="filter-affected-target"
              id="filter-affected-target"
              placeholder="Username"
              debounceTimer={300}
              onChange={event => this.filterAffectedTarget(event.target.value)}
            />
          </div>
          <div className="filter-ip-input-wrapper">
            <Label htmlFor="filter-ip" text="IP address" />
            <Input
              name="filter-ip"
              id="filter-ip"
              placeholder="A valid IP address..."
              debounceTimer={300}
              error={ipError}
              onChange={event => this.filterIP(event.target.value)}
            />
          </div>
          </div>
        </div>
        <TableHeader customContent id="logs-header">
          <TableCell className="order-wrapper" customContent>
            <span>Time</span>
            <div className="order-icons">
              <span id="asc-icon"><Icon color={value === true ? 'black' : 'grey'} actionable onClick={() => orderContent(true, 'asc')} name="arrowUp" width="10" height="10" /></span>
              <span id="desc-icon"><Icon color={value === true ? 'grey' : 'black'} actionable onClick={() => orderContent(false, 'asc')} name="arrowDown" width="10" height="10" /></span>
            </div>
          </TableCell>
          <TableCell text="User" />
          <TableCell text="Action type" />
          <TableCell text="Affected target" />
          <TableCell text="IP address" />
        </TableHeader>
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => (bindActionCreators({
  setNotification: notificationsActions.setNotification,
  deleteNotification: notificationsActions.deleteNotification,
}, dispatch));

export default connect(null, mapDispatchToProps)(LogsHeader);
