import * as types from './actionTypes';
import { decodeTokenPayload, createUUID, timeUntilFromMinutes } from '../../utils';
import { openModal } from './modals';
import history from '../../components/history';

const signupMessage = error => ({ type: types.SIGNUP_SUCCESS, payload: error });

const loginAttempt = () => ({ type: types.LOGIN_ATTEMPT });
const loginSuccess = user => ({ type: types.LOGIN_SUCCESS, payload: user });
const loginFailure = error => ({ type: types.LOGIN_FAILURE, payload: error });

// const getErrorMessage = error => ((error.response) ? error.response.dataMessage : error);

const logoutSuccess = () => ({ type: types.LOGOUT_SUCCESS });
const logoutFailure = error => ({ type: types.LOGOUT_FAILURE, payload: error });

const recoverPasswordSuccess = () => ({ type: types.RECOVER_PASSWORD_SUCCESS });
// const recoverPasswordFailure = error => ({ type: types.RECOVER_PASSWORD_FAILURE, payload: error });
const resetPasswordSuccess = message => ({ type: types.RESET_PASSWORD_SUCCESS, payload: message });
const resetPasswordFailure = error => ({ type: types.RESET_PASSWORD_FAILURE, payload: error });
const resetPasswordLinkSuccess = message => ({ type: types.RESET_PASSWORD_LINK_SUCCESS, payload: message });
const resetPasswordLinkFailure = error => ({ type: types.RESET_PASSWORD_LINK_FAILURE, payload: error });

const passwordConfigSuccess = config => ({ type: types.PASSWORD_CONFIG_SUCCESS, payload: config });
const usernameConfigSuccess = config => ({ type: types.USERNAME_CONFIG_SUCCESS, payload: config });

export const clearUserNotification = () => ({ type: types.CLEAR_AUTH_MESSAGE });


const loadUserGroupsSuccess = groups => ({ type: types.LOAD_USER_GROUPS_SUCCESS, payload: groups });
const loadUserBotsSuccess = bots => ({ type: types.LOAD_USER_BOTS_SUCCESS, payload: bots });

const getConfigsSuccess = configs => ({ type: types.GET_CONFIGS, payload: configs });
const getPublicConfigsSuccess = configs => ({ type: types.GET_PUBLIC_CONFIGS, payload: configs });
const parseFetchResponse = response => response.json().then(text => ({
  json: text,
  meta: response,
}));

// const parseGetResponse = response => response.user.then(text => ({
//   json: text,
// }));

export const signup = data => async (dispatch, getState, api) => {
  try {
    await api.postbody('/signup', data)
      .then(parseFetchResponse)
      .then(({ json, meta }) => {
        if (meta.status === 200) {
          dispatch(signupMessage('Signup Success.'));
        } else {
          dispatch(signupMessage(json));
        }
      });
  } catch (error) {
    throw new Error(error);
  }
};

export const getLoggedInUserGroups = () => async (dispatch, getState, api) => {
  const { user } = await getState().auth;
  const groups = await api.get(`/users/${user.uuid}/groups`);
  dispatch(loadUserGroupsSuccess(groups));
};

export const getLoggedInUserBots = () => async (dispatch, getState, api) => {
  const { user } = await getState().auth;
  const bots = await api.get(`/users/${user.uuid}/bots`);
  dispatch(loadUserBotsSuccess(bots));
  return bots;
};

export const refreshLoggedInUser = () => async (dispatch) => {
  await dispatch(getLoggedInUserGroups());
  dispatch(getLoggedInUserBots());
};

const isExpiredPassword = resp => resp.respCode === 400
  && resp.error === 'User credentials have expired';

export const login = credentials => async (dispatch, getState, api) => {
  try {
    dispatch(clearUserNotification());
    dispatch(loginAttempt());
    const response = await api.login(credentials);

    if (isExpiredPassword(response)) {
      throw new Error('Your password has expired. Please reset.');
    }

    if (!response.userAuthorisation) {
      throw new Error('Authentication Failure');
    }

    const accessToken = response.token.access_token;
    // save the user in localstorage to make api call (to /users/:id)
    await localStorage.setItem('user', JSON.stringify({
      name: credentials.username,
      token: accessToken,
    }));
    const userId = decodeTokenPayload(accessToken).uuid;
    const loggedinUser = await api.get(`/users/${userId}`);

    if (!loggedinUser.username) {
      throw new Error('Unable to get user information.');
    }
    if (loggedinUser.isBot) {
      await localStorage.removeItem('user');
      throw new Error('Cannot login as a bot.');
    }
    // update the user in localstorage
    loggedinUser.token = accessToken;
    localStorage.setItem('user', JSON.stringify({ ...loggedinUser, bots: [], groups: [] }));
    const userGroups = await api.get(`/users/${userId}/groups`);
    const userBots = await api.get(`/users/${userId}/bots`);
    loggedinUser.groups = userGroups;
    loggedinUser.bots = userBots;
    await dispatch(loginSuccess(loggedinUser));

    const configs = await api.get('/config/all');
    await dispatch(getConfigsSuccess(configs));

    const passExpConfig = getState().configs.configs['password.expiration.notification.minutes'];
    if (loggedinUser.passwordExpirationMinutes <= passExpConfig.value) {
      const id = createUUID();
      const timeUntil = timeUntilFromMinutes(loggedinUser.passwordExpirationMinutes);
      dispatch(openModal({ // Dispatch action
        id, // from utils
        type: 'Password_Expiration', // Need to make in components/modals/Notifications.jsx
        until: `Your password will expire in ${timeUntil}.`,
        text: 'Do you want to change your password now?', // message
        title: 'PASSWORD CHANGE',
        // onClose: () => console.log("close"), // Fire at close event
        onConfirm: () => history.push(`/users/${loggedinUser.uuid}`), // Fire at confirm event
      }));
    }
  } catch (error) {
    dispatch(loginFailure(error.message));
  }
};

// send a link to reset password,
// Do not send error msg back, security hole as user can deduce which email exists
export const recoverPassword = email => async (dispatch, getState, api) => {
  await api.postbody('/users/password/reset', email, true);
  dispatch(loginFailure(null));
  dispatch(recoverPasswordSuccess());
};


export const checkPasswordResetToken = token => async (dispatch, getState, api) => {
  try {
    const response = await api.postbody(`/users/password/reset/validatetoken?token=${token}`, true);
    if (response.status && response.status !== 200) {
      const json = await response.json();
      throw Error(json.message);
    }
  } catch (error) {
    dispatch(resetPasswordFailure(error.message));
  }
};

// change password after clicking the link sent via email
export const resetPassword = data => async (dispatch, getState, api) => {
  try {
    const response = await api.postbody(`/users/password/change?token=${data.token}`, data.password, true);
    if (response.status && response.status !== 200) {
      const json = await response.json();
      throw Error(json.message);
    }
    dispatch(resetPasswordSuccess('Password has been Reset Successfully'));
    history.push('/login');
  } catch (error) {
    dispatch(resetPasswordFailure(error.message));
  }
};

// for sysadmins to get the reset password links of other users
export const resetPasswordLink = email => async (dispatch, getState, api) => {
  try {
    const response = await api.postbody('/users/password/reset/link', email, true);
    const jsonResponse = await response.json();
    dispatch(resetPasswordLinkSuccess(jsonResponse));
  } catch (error) {
    dispatch(resetPasswordLinkFailure(error));
  }
};

export const getPasswordConfig = () => async (dispatch, getState, api) => {
  const pwdConfig = await api.get('/config');
  dispatch(passwordConfigSuccess(pwdConfig.find(conf => conf.name === 'password.strength')));
};

export const getUsernameConfig = () => async (dispatch, getState, api) => {
  const configs = await api.get('/config');
  dispatch(usernameConfigSuccess(configs.find(conf => conf.name === 'username.pattern')));
};

export const logout = () => async (dispatch, getState, api) => {
  try {
    const configs = JSON.parse(localStorage.getItem('configs'));
    const enabled = configs.find(conf => conf.name === 'saml.enabled');
    if (enabled.value) {
      localStorage.removeItem('configs');
      localStorage.removeItem('user');
      localStorage.removeItem('deniedPolicies');
      // await dispatch(logoutSuccess());
      window.location = `${window.location.origin}/saml/logout`;
    } else {
      await api.get('/users/logout', true).catch(() => { });
      localStorage.removeItem('configs');
      localStorage.removeItem('user');
      localStorage.removeItem('deniedPolicies');
      dispatch(logoutSuccess());
    }
  } catch (error) {
    dispatch(logoutFailure(error));
    localStorage.removeItem('configs');
    localStorage.removeItem('user');
  }
};

export const samlLogin = url => async (dispatch, getState, api) => {
  try {
    const response = await fetch(`${window.location.origin}/token`, {
      method: 'GET',
      credentials: 'include',
    });
    if (!response.ok && response.status === 401) {
      window.location = url;
    } else if (!response.ok && response.status === 403) {
      const configs = await api.get('/config');
      history.push({
        pathname: '/error',
        state: {
          errorMessage: configs.find(conf => conf.name === 'access.denied.message').value,
          errorLink: configs.find(conf => conf.name === 'access.denied.link').value,
          linkText: configs.find(conf => conf.name === 'access.denied.link').description,
          from: history.location,
        },
      });
    } else {
      let token = await response.text();
      token = JSON.parse(token).access_token;
      const decodedToken = decodeTokenPayload(token);

      const userId = decodedToken.uuid;
      await localStorage.setItem('user', JSON.stringify({
        name: decodedToken.user_name,
        token,
      }));

      const loggedinUser = await api.get(`/users/${userId}`);
      if (!loggedinUser.username) {
        throw new Error('Unable to get user information.');
      }

      const userGroups = await api.get(`/users/${userId}/groups`);
      const userBots = await api.get(`/users/${userId}/bots`);
      loggedinUser.groups = userGroups;
      loggedinUser.bots = userBots;
      // update the user in localstorage
      loggedinUser.token = token;
      await localStorage.setItem('user', JSON.stringify(loggedinUser));
      await dispatch(loginSuccess(loggedinUser));

      const configs = await api.get('/config/all');
      await dispatch(getConfigsSuccess(configs));

      const passExpConfig = getState().configs.configs['password.expiration.notification.minutes'];
      if (loggedinUser.passwordExpirationMinutes <= passExpConfig.value) {
        const id = createUUID();
        const timeUntil = timeUntilFromMinutes(loggedinUser.passwordExpirationMinutes);
        dispatch(openModal({ // Dispatch action
          id, // from utils
          type: 'Password_Expiration', // Need to make in components/modals/Notifications.jsx
          until: `Your password will expire in ${timeUntil}.`,
          text: 'Do you want to change your password now?', // message
          title: 'PASSWORD CHANGE',
          // onClose: () => console.log("close"), // Fire at close event
          onConfirm: () => history.push(`/users/${loggedinUser.uuid}`), // Fire at confirm event
        }));
      }
    }
  } catch (error) {
    dispatch(loginFailure(error.message));
  }
};

const stripVersion = (version) => {
  const parts = version.value.split(/[-.~]/);
  return {
    name: 'application.version',
    value: `${parts[0]}.${parts[1]}.${parts[2]}`,
  };
};

export const getPublicConfigs = () => async (dispatch, getState, api) => {
  try {
    const configs = await api.get('/config');
    const version = await api.get('/version');
    configs.push(stripVersion(version));
    dispatch(getPublicConfigsSuccess(configs));
  } catch (error) {
    if (error.message === '504') {
      history.push({
        pathname: '/error',
        state: {
          errorMessage: 'Could not connect to server. Please try again later',
          from: history.location,
          // errorLink: 'errorLink',
          // linkText: 'Link text',
        },
      });
    }
  }
};
export const getConfigs = () => async (dispatch, getState, api) => {
  const configs = await api.get('/config/all');
  const version = await api.get('/version');
  configs.push(stripVersion(version));
  localStorage.setItem('configs', JSON.stringify(configs));
  dispatch(getConfigsSuccess(configs));
};

export const clearLoginError = () => async (dispatch) => {
  dispatch(loginFailure(null));
};
