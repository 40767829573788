/* eslint-disable react/no-unused-state */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
  Input, Button, Dropdown, Spinner, Label,
  // Pagination,
} from 'rtm-ui-components';
import AddUserGroups from './AddUserGroups/AddUserGroups';
import AddEntityWrapper from '../../../Wrappers/AddEntityWrapper/AddEntityWrapper';
import * as groupActions from '../../../../store/actions/groups';
import * as userActions from '../../../../store/actions/users';
import { groupVisibilityFilters, paginationLimit } from '../../../../constants';
import DetailsCard from '../../../SharedComponents/DetailsCard/DetailsCard';
import HasRole from '../../../../roles/HasRole';
import Table from '../../../SharedComponents/Table/Table';
import AddFirstEntity from '../../../SharedComponents/AddFirstEntity/AddFirstEntity';
import '../../../../assets/stylesheets/DetailsViewContent.css';
import { entityCardDetails, ucfirst } from '../../../../utils';
import ChangeView from '../../../SharedComponents/ChangeView/ChangeView';

class UserGroups extends Component {
  state = {
    filter: '',
    visibilityFilter: 'all',
    addGroups: false,
    cards: true,
    limit: paginationLimit,
    page: 0,
  };

  async componentDidMount() {
    const { getSelectedUserGroups } = this.props;
    const settings = JSON.parse(localStorage.getItem('userSettings'));
    if (settings && settings.detailsListingCards !== undefined && !settings.detailsListingCards) {
      this.setState({ cards: false });
    }
    await getSelectedUserGroups();
  }

  onChangeFilter = async (value, type) => {
    const { getSelectedUserGroups } = this.props;
    switch (type) {
      case 'visibility':
        await this.setState({ visibilityFilter: value });
        break;
      default:
        break;
    }
    await getSelectedUserGroups();
  }

  changeListingStyle = async () => {
    const { cards } = this.state;
    let settings = JSON.parse(localStorage.getItem('userSettings'));
    if (!settings) {
      settings = {};
    }
    settings.detailsListingCards = !cards;
    await localStorage.setItem('userSettings', JSON.stringify(settings));
    await this.setState({ cards: !cards });
  }

  filteredGroups = (groups, filter, visibilityFilter) => {
    let filteredGroups = [...groups];
    if (visibilityFilter !== 'all') {
      filteredGroups = filteredGroups.filter(({ group }) => (visibilityFilter === 'public' && group.isPublic) || (visibilityFilter === 'private' && !group.isPublic));
    }
    if (filter) {
      filteredGroups = filteredGroups.filter(({ group }) => group.name.includes(filter) || group.longname.includes(filter));
    }
    return filteredGroups;
  }

  toggleModal = () => {
    const { addGroups } = this.state;
    this.setState({ addGroups: !addGroups });
  }

  changePage = async (activePage) => {
    const { getSelectedUserGroups } = this.props;
    await this.setState({ page: activePage });
    getSelectedUserGroups();
  }

  removeUserGroup = async (user, group) => {
    const { removeUserGroup, getSelectedUserGroups } = this.props;
    await removeUserGroup(user, group);
    getSelectedUserGroups();
  }

  addGroupAdmin = async (user, group) => {
    const { addGroupAdmin, getSelectedUserGroups } = this.props;
    await addGroupAdmin(user, group);
    getSelectedUserGroups();
  }

  removeGroupAdmin = async (user, group) => {
    const { removeGroupAdmin, getSelectedUserGroups } = this.props;
    await removeGroupAdmin(user, group);
    getSelectedUserGroups();
  }

  render() {
    const {
      user, getSelectedUserGroups, loading,
    } = this.props;
    const {
      filter, visibilityFilter, addGroups, cards,
      // page, limit,
    } = this.state;
    const detailsgroupVisibilityFilters = groupVisibilityFilters.map((filt) => {
      const tempFilter = { ...filt };
      tempFilter.id = `details-${tempFilter.id}`;
      return tempFilter;
    });
    const userGroupsFiltered = user && user.groups;

    let renderedType;
    if ((!userGroupsFiltered || !userGroupsFiltered.length) && loading) {
      renderedType = (
        <div className="details-list-container table">
          <Spinner />
        </div>
      );
    } else if (!userGroupsFiltered || !userGroupsFiltered.length) {
      renderedType = (
        <div className="details-list-container table">
          <AddFirstEntity iconName="addGroupIcon" text="There are no groups." />
        </div>
      );
    } else if (cards) {
      const groupsCards = this.filteredGroups(user.groups, filter, visibilityFilter).map(({
        group, role, isParentGroup, childGroups,
      }) => {
        const details = entityCardDetails(group);
        if (isParentGroup) {
          details.push({
            title: 'Member via',
            value: `${childGroups.join(', ')}`,
          });
        }
        details.push({
          title: 'User\'s role',
          value: ucfirst(role.name),
        });
        const actions = isParentGroup ? [] : [
          {
            id: role.name === 'user' ? 'add-admin' : 'remove-admin',
            title: role.name === 'user' ? 'Promote to admin' : 'Remove admin',
            onClick: role.name === 'user' ? () => this.addGroupAdmin(user, group) : () => this.removeGroupAdmin(user, group),
            requiredRole: 'groupEdit',
            resource: group,
          },
          {
            id: 'remove-group',
            title: 'Remove user from group',
            onClick: () => this.removeUserGroup(user, group),
            requiredRole: 'groupEdit',
            resource: group,
          },
        ];
        return (
          <DetailsCard
            key={group.uuid}
            isAdmin={role.name !== 'user'}
            isPrivate={!group.isPublic}
            icon={isParentGroup ? { name: 'group', color: 'purple' } :{ name: 'group', color: 'primary' }}
            name={group.longname}
            description={group.name}
            details={details}
            link={{ title: 'Edit Group', target: `/groups/${group.uuid}` }}
            actions={actions}
          />
        );
      });
      renderedType = (
        <div className="details-list-container cards">
          { groupsCards }
        </div>
      );
    } else {
      const groupsTableContent = this.filteredGroups(user.groups, filter, visibilityFilter).map(({
        group, role, isParentGroup, childGroups,
      }) => {
        const details = entityCardDetails(group);
        if (isParentGroup) {
          details.push({
            title: 'Member via',
            value: `${childGroups.join(', ')}`,
          });
        }
        details.push({
          title: 'User\'s role',
          value: ucfirst(role.name),
        });
        const actions = isParentGroup ? [] : [
          {
            id: role.name === 'user' ? 'add-admin' : 'remove-admin',
            title: role.name === 'user' ? 'Promote to admin' : 'Remove admin',
            onClick: role.name === 'user' ? () => this.addGroupAdmin(user, group) : () => this.removeGroupAdmin(user, group),
            requiredRole: 'groupEdit',
            resource: group,
          },
          {
            id: 'remove-group',
            title: 'Remove user from group',
            onClick: () => this.removeUserGroup(user, group),
            requiredRole: 'groupEdit',
            resource: group,
          },
        ];
        return {
          group, role, details, actions, childGroups: isParentGroup ? childGroups.join(', ') : '',
        };
      });
      const groupsTable = <Table groups={groupsTableContent || null} />;
      renderedType = (
        <div className="details-list-container table">
          { groupsTable }
          <div>
            {/* searchGroupUsers is not a function, has to be fixed */}
            {/* { groupsTableMapped.length >= 20 ? (
              <Pagination
                id="group-users-pagination"
                totalPages={user.totalPages}
                activePage={user.page}
                onPageChange={this.changePage}
              />
            ) : null } */}
          </div>
        </div>
      );
    }
    return (
      <div className="details-membership-row" id="users-page">
        <div className="user-group-form userGroups">
          <div>
            <div className="details-inputs">
              <Input
                id="filter-user-groups-input"
                placeholder="Filter Groups"
                onChange={({ target }) => this.setState({ filter: target.value })}
                icon="search"
              />
              <div className="details-left">
                <HasRole requiredRole="userEdit" resource={user}>
                  <Button
                    id="add-user-to-group"
                    variant="primary"
                    iconColor="black"
                    icon="add"
                    onClick={this.toggleModal}
                  >Add User To Groups
                  </Button>
                </HasRole>
                <ChangeView cards={cards} changeListingStyle={this.changeListingStyle} />
              </div>
            </div>
            <div className="filter-container">
              <div className="filters-wrapper">
                <div>
                  <Label text="Visibility" />
                  <Dropdown
                    defaultValue="All"
                    options={detailsgroupVisibilityFilters}
                    onChange={item => this.onChangeFilter(item.value, 'visibility')}
                  />
                </div>

              </div>
            </div>
          </div>
          <div className="details-entity-listing">
            { renderedType }
          </div>
          {addGroups ? (
            <AddEntityWrapper
              onClose={this.toggleModal}
              open={addGroups}
              title="Add User to Groups"
            >
              <AddUserGroups
                addAction={() => { getSelectedUserGroups(); this.toggleModal(); }}
                selectedUser={user}
              />
            </AddEntityWrapper>
          ) : null}
        </div>
      </div>
    );
  }
}


const mapDispatchToProps = dispatch => (bindActionCreators({
  removeUserGroup: groupActions.removeUserGroup,
  addGroupAdmin: groupActions.addGroupAdmin,
  removeGroupAdmin: groupActions.removeGroupAdmin,
  getSelectedUserGroups: userActions.getSelectedUserGroups,
}, dispatch));

const mapStateToProps = state => ({
  loading: state.users.isSearchingUserMemberships,
});

export default connect(mapStateToProps, mapDispatchToProps)(UserGroups);
