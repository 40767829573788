import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Route, Redirect } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { SideNavigation, Icon, Banner } from 'rtm-ui-components';
import * as notificationsActions from '../../store/actions/notifications';

class LoggedInRoute extends Component {
  state = {
    navigationIsLarge: true,
  }

  componentDidMount() {
    const { getNotification } = this.props;
    getNotification();
    const settings = JSON.parse(localStorage.getItem('userSettings'));
    if (settings && settings.navigationIsLarge !== undefined && !settings.navigationIsLarge) {
      this.setState({ navigationIsLarge: false });
    }
  }

  toggleLargeNavigation = () => {
    const { navigationIsLarge } = this.state;
    let settings = JSON.parse(localStorage.getItem('userSettings'));
    if (!settings) {
      settings = {};
    }
    settings.navigationIsLarge = !navigationIsLarge;
    localStorage.setItem('userSettings', JSON.stringify(settings));
    this.setState({ navigationIsLarge: !navigationIsLarge });
  }

  render() {
    const {
      // eslint-disable-next-line no-shadow
      component: Component, user, notification, showNotification, hideNotification, apiDocsLink, docsLink, ...rest
    } = this.props;
    const { navigationIsLarge } = this.state;

    const links = [
      {
        id: 'users-nav',
        icon: 'userPlain',
        text: 'Users',
        to: '/users',
      },
      {
        id: 'groups-nav',
        icon: 'groupPlain',
        text: 'Groups',
        to: '/groups',
      },
      {
        id: 'bots-nav',
        icon: 'botPlain',
        text: 'Bots',
        to: '/bots',
      },
    ];
    if (user && user.appRole === 'sysadmin') {
      links.push({
        id: 'sysadmin-nav',
        icon: 'sysadminPlain',
        text: 'Sysadmin',
        to: '/admin',
      });
    }
    const userActions = [
      {
        id: 'profile',
        to: user ? `/users/${user.uuid}` : '/logout',
        exact: true,
        text: 'Profile',
      },
    ];
    if (docsLink) {
      userActions.push({
        id: 'docs',
        to: docsLink.value,
        external: true,
        text: 'User guide',
      });
    }
    if (apiDocsLink) {
      userActions.push({
        id: 'api-docs',
        to: apiDocsLink.value,
        external: true,
        text: 'API documentation',
      });
    }
    userActions.push({
      id: 'logout',
      to: '/logout',
      exact: true,
      text: 'Logout',
    });
    //Fugly parsing to get linebreaks correctly on banner without changing code on components
    //Due to horrid workflow
    let newMessage = ""
    const a = notification.message
    if(a != null){
      if(a.includes("\\n")){
        const b = a.split("\\n")
        for(let i = 0; i < b.length; i++){
          newMessage += b[i] + "\n"
        }
      }
    }
    else{
      newMessage = notification.message
    }
    return (
      <Route
        {...rest}
        render={(routeProps) => {
          if (!user) {
            return <Redirect to={{ pathname: '/login', state: { from: routeProps.location } }} />;
          }
          return (
            <React.Fragment>
              {
                notification && showNotification && <Banner id="banner" text={newMessage == "" ? notification.message : newMessage} onClose={hideNotification} />
              }
              <SideNavigation
                hasBanner={showNotification}
                headerIconClosed={<Icon id="eficode-logo" name="EficodeWhite" width="80" height="39" ariaLabel="Eficode logo" />}
                headerIconOpened={<Icon style={{ paddingLeft: '12.2px' }} id="root-logo" name="RTMWhite" width="170" height="39" ariaLabel="Root logo" />}
                isLarge={navigationIsLarge}
                onToggleLarge={this.toggleLargeNavigation}
                links={links}
              />
              <Component
                {
                ...{
                  ...routeProps,
                  hasBanner: showNotification,
                }
                }
                user={user}
                userActions={userActions}
                isLarge={navigationIsLarge}
              />
            </React.Fragment>
          );
        }}
      />
    );
  }
}

const mapDispatchToProps = dispatch => (bindActionCreators({
  getNotification: notificationsActions.getNotification,
  hideNotification: notificationsActions.hideNotification,
}, dispatch));

const mapStateToProps = state => ({
  user: state.auth.user,
  apiDocsLink: state.configs.configs['swagger.ui.link'],
  docsLink: state.configs.configs['documentation.link'],
  notification: state.notifications.notification,
  showNotification: !state.notifications.hidden,
});

export default connect(mapStateToProps, mapDispatchToProps)(LoggedInRoute);
